@import '~rsuite/styles/index.less';
// Enable dark mode
@enable-dark-mode: true;

@primary-color-dark: #FF640C;

@base-color: #FF640C;

html {
  scroll-behavior: smooth;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 10px;
}

@media only screen and (max-width: 480px) {
  .logo-container {
    width: 50px;
  }

  .users-stack {
    display: none;
  }
}

@font-face {
  font-family: VagRounded;
  src: url('./fonts/VagRounded.otf') format("opentype");
}

.logo-text {
  font-family: VagRounded;
  color: white;
  position: relative;
  cursor: pointer;
}

.brand {
  color: @base-color
}

.expanded-row {
  max-height: 500px;
}

.interview-container {
  min-height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.interview-progress {
  display: flex;
}

.user-dropdown .rs-dropdown-toggle {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  padding: 7px 32px 7px 7px !important;
  align-items: center;
}

.rs-nav-horizontal {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #1a1d24;;
}

.users-stack {
  padding: 6px;
  width: 1px;
  justify-content: flex-end;
  position: relative;

  .rs-avatar {
    border: 2px solid #ffffff6e;
  }
}

.cards-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  justify-content: center;
}

.interview-tools-container {
  display: flex;
  flex-direction: row;
}

.side-bar .rs-nav-item .rs-nav-item-content {
  text-align: center;
  margin-top: 10px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 480px) {
  .content {
    align-items: flex-end;
    height: 100%;
    padding: 0 0 10px 0;
  }

}

.question-wrapper {
  transition: all .5s ease-in-out;
}

@media only screen and (max-width: 480px) {
  .question-wrapper {
    padding: 20px 0;
  }

}

.nav-btn {
  position: fixed !important;
  bottom: 15px;
  right: 15px;
}

.private-router-loader .rs-loader-spin {
  display: none;
}

.navbar-link {
  .rs-nav-item-content {
    grid-gap: 10px;
    padding: 20px!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  a {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .rs-icon {
    margin-right: 0!important;
  }
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

.ProseMirror {
  padding: 10px;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror-focused {
  outline: none;
}


/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}
